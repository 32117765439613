import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import {
  getBreadCrumb,
  removeHtmlTag,
  youtube_parser,
} from '../../../../Utils/Utils';
import Layout from '../../../../Layout';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import Seo from '../../../../components/Seo/Seo';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import VideoYoutube from '../../../../components/Video/VideoYoutube';
import VisuelComponent from '../../../../components/ContentType/VisuelComponent/VisuelComponent';

import './styles.scss';
import useMedias from '../../../../hooks/useMedias';

let classNames = require('classnames');

const ColasHistorySingle = ({ data }) => {
  const { processUrl, getImage } = useMedias();

  const intl = useIntl();
  const detailHistoryData = data?.detailHistoryData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, detailHistoryData.path?.alias);
  const metaTags = detailHistoryData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  const [allText, setAllText] = useState('');

  let globalText = '';

  useEffect(() => {
    setAllText(
      removeHtmlTag(
        `${detailHistoryData.title != undefined &&
          detailHistoryData.title != null
          ? detailHistoryData.title
          : ''
        }.${detailHistoryData.body?.processed != undefined &&
          detailHistoryData.body?.processed != null
          ? detailHistoryData.body?.processed
          : ''
        }${detailHistoryData.field_text_2?.processed != undefined &&
          detailHistoryData.field_text_2?.processed != null
          ? detailHistoryData.field_text_2?.processed
          : ''
        }${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={detailHistoryData?.field_metatag?.title ? detailHistoryData?.field_metatag?.title : metaTitle}
        description={detailHistoryData?.field_metatag?.description ? detailHistoryData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'colas_history_inner_page')}>
        <PageBannerStyle
          visuel={getImage(
            imagesArray,
            detailHistoryData?.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={true}
          breadcrumbData={{
            //grandParentPage: { title: 'Acceuile', url: '/' },
            gparent: {
              title: breadCrumb?.grandParent?.name,
              url: breadCrumb?.grandParent?.link,
            },
            parentPage: {
              title: breadCrumb?.parent?.name,
              url: breadCrumb?.parent?.link,
            },
            currentPage: {
              title: breadCrumb?.current?.name,
              url: breadCrumb?.current?.link,
            },
            locale: detailHistoryData.langcode,
          }}
        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title={detailHistoryData.title}
              description={detailHistoryData.body?.processed}
            />
            {allText &&
              detailHistoryData.field_version_audio &&
              allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                />
              )}
          </div>
        </PageBannerStyle>
        {detailHistoryData.relationships?.field_blocs?.map((block, i) => {
          globalText += `${block.field_title?.processed != undefined &&
            block.field_title?.processed != null
            ? block.field_title?.processed
            : ''
            }.${block.body?.processed != undefined && block.body?.processed != null
              ? block.body?.processed
              : ''
            }`;
          switch (block.__typename) {
            case 'block_content__basic':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_lauch_wattway'
                  )}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection title={block.field_title?.processed} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: block.body?.processed,
                      }}
                    ></div>
                    <VisuelComponent
                      extremeLeft={
                        block.field_no_padding &&
                          block.field_alignment === 'left'
                          ? true
                          : false
                      }
                      extremeRight={
                        block.field_no_padding &&
                          block.field_alignment === 'right'
                          ? true
                          : false
                      }
                      visuel={getImage(
                        imagesArray,
                        block?.relationships?.field_image?.drupal_internal__mid
                      )}
                      size="two_third"
                      alt=""
                    />
                  </div>
                </section>
              );

            case 'block_content__block_video':
              return (
                <section className="section_content" key={i}>
                  {
                    <div className="wrapper_page_xs">
                      <TitleSection title={block.field_title?.processed} />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: block.body?.processed,
                        }}
                      ></div>
                      <div className="video_container">
                        <VideoYoutube
                          cover={processUrl(
                            getImage(
                              imagesArray,
                              block?.relationships?.field_image
                                ?.drupal_internal__mid
                            )?.uri?.url
                          )}
                          videoID={youtube_parser(block.field_link?.uri)}
                          videoTitle={block.field_title?.processed}
                          textCookieBlock={intl.formatMessage({ id: 'common.accept_cookie_for_videos' })}
                          btnCookieBlock={intl.formatMessage({ id: 'common.accept_cookie_btn' })}
                          obj={{
                            height: '390',
                            width: '640',
                            playerVars: {
                              autoplay: 0,
                              controls: 0,
                              disablekb: 1,
                              rel: 0,
                              showinfo: 0,
                            },
                          }}
                        />
                      </div>
                    </div>
                  }
                </section>
              );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query DetailHistoryTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    detailHistoryData: allNodeHistoire(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_version_audio
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          path {
            alias
            langcode
          }
          body {
            processed
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  field_title {
                    processed
                  }
                  field_no_padding
                  field_alignment
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                }
                ... on block_content__block_video {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  field_link {
                    title
                    uri
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
          langcode
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default ColasHistorySingle;
